@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
@import "./globals.css";
@import "./variables.scss";

     /* GLOBAL CLASSES */
.test {
    color: $darkPrimary;
}
a{
    text-decoration: none !important;
    color: black !important;
}
p
{
    margin: 0 !important;
}
ul
{
    list-style: none;
    padding: 0;
    margin: 0;
}
li {
    margin: 0;
}
.text-myPrimary
{
    color: $primaryColor;
}
.text-myMediumGray
{
    color: $mediumGray;
}
.fs-14
{
    font-size: 14px;
}
.myFs-10
{
    font-size: 10px;
    color: $mediumGray;
}
.w-fitContent 
{
    min-width: fit-content;
}
.cursor-pointer
{
    cursor: pointer;
}
.myText-warning {
    color: $secondryColor;
    font-size: 14px;
    margin-top: -10px;
}
            /*  START CUSTOMIZE BTN */
.primary-small-button, .secondary-small-button
{
    color: $baseColor;
    font-size: 14px;
    border-radius: $borderRadius;
    padding: 10px;
    border: 0;
}
.primary-medium-button
{
    color: $baseColor;
    font-size: 16px;
    border-radius: $borderRadius;
    padding: 15px;
    border: 0;
    min-width: 70px;
}
.primary-small-button, .primary-medium-button
{
    background-color: $primaryColor;
}
.secondary-small-button
{
    background-color: $mediumGray;
}

            /* END CUSTOMIZE BTN */
            /* START PRIMARY INPUT */
.primary-input
{
    border-radius: $borderRadius;
    padding: 5px 10px;
    background-color: transparent;
    border: 1px solid $babyBlue-100;
    width: 100%;
    &::placeholder
    {
        font-size: 16px;
    }
    &:focus-visible {
        outline: -webkit-focus-ring-color auto 0px;
        font-size: 16px;
        padding: 5px 10px !important;
    }
}

            /* END PRIMARY INPUT */  
.text-error
{
    color: $fourthColor;
    font-size: 14px;
} 
.filter-popup, .contacts-popup
{
    display: none;
}

                /* S T A R T   N A V B A R  */
.navbar
{
    position: fixed !important;   
    /*  call to action */
    top: 0px;
    right: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 76px;
    padding: 10px 0;
    background-color: $baseColor;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; 
    .logo-navbar {
        max-height: 56px;
    }
    .btn-navbar {

    }
    .icon-search-navbar
    {
        color: $primaryColor;
        border-radius: 15px;
        font-size: 24px;
    }
    .filter
    {
        color: $primaryColor;
        font-size: 24px;
    }
    .profile-menu-dropdown {
        .dropdown-toggle {
            background-color: $baseColor;
            border: none;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: $borderRadius !important;
            width: 90px;
            .profile-menu-dropdown-toggle {
                background-color: $baseColor;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .profile-icon {
                    fill: $primaryColor;
                    font-size: 20px !important;
                }
                .menu-icon {
                    color: $darkGray;
                    font-size: 22px;
                }
            }
        }
        .dropdown-item.active, .dropdown-item:active {
            background-color: $babyBlue-50;
        }
    }
}
.search-navbar
{
    display: none;
    // background-color: transparent;
    // border: 1px solid $primaryColor;
    // border-radius: 20px;
    // width: 100%;
    // padding: 20px 14px 20px 40px;
    // font-size: 16px;
    // margin-inline: auto;
    .layout-search
    {
        position: fixed;
        inset: 0;
        background-color: #0000004d;
        z-index: 9;
        .parent-search
        {
            /* background-color: $baseColor; */
            background-color: $baseColor;
            position: relative;
            top: 76px;
            z-index: 1000;
            /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
            .search
            {
                position: relative;
                width: 70%;
                margin-inline: auto;
                padding: 20px 0;
                .icon-search-navbar-old
                {
                    background-color: $primaryColor;
                    color: $baseColor;
                    border-radius: 20px;
                    font-size: 32px;
                    padding: 10px ;
                    position: absolute;
                    top: 50%;
                    left: 8px;
                    transform: translateY(-50%);
                }
                .css-13cymwt-control
                {
                    background-color: transparent;
                    border: 1px solid $primaryColor;
                    border-radius: 20px;
                    width: 100%;
                    padding: 15px 14px 15px 40px;
                    font-size: 16px;
                    margin-inline: auto;
                    border-radius: 20px !important;
                }
                .css-1xc3v61-indicatorContainer
                {
                    /* opacity: 0; */
                }
                .css-vnxml5-control
                {
                    outline: -webkit-focus-ring-color auto 0px;
                    padding: 15px 14px 15px 40px;
                    font-size: 16px;
                    border-radius: 20px !important;
                }
                .css-tj5bde-Svg
                {
                    /* opacity: 0; */
                }
                .css-1u9des2-indicatorSeparator
                {
                    /* opacity: 0; */
                }
                .css-qbdosj-Input {
                    color: $primaryColor !important;
                }
                .css-1wy0on6 {
                    /* opacity: 0 !important; */
                }
                .parent-icon-search-navbar-old
                {
                    position: absolute;
                    top: 50%;
                    left: 5px;
                    transform: translateY(-50%);
                    border: 0;
                }
                .css-15lsz6c-indicatorContainer
                {
                    /* color: transparent !important; */
                }
            }
        }
    }
}


            /* E N D   N A V B A R  */
            /* S T A R T   F O O T E R  */
.footer
{
    
    width: 100%;
    padding: 10px 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; 
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9;
    background-color: $baseColor;
}
.preview-unit-footer .footer
{
    box-shadow: none; 
    background-color: transparent;
    position: static;
}
.line-footer
{
    border-top: 1px solid #ddddddc2;
    text-align: right;
    width: 100%;
}
.preview-unit-footer
{
    margin-bottom: 65px;
}
.parent-preview-unit-footer {
    border-top: 1px solid#ddddddc2;
    /* background-color: var(--babyBlue-50); */
}
.fs-footer
{
    font-size: 14px;
}
.logo-footer
{
    height: 45px;
}
            /* E N D   F O O T E R  */
            /* S T A R T   M A I N   P  A G E   */
.main {
    /* min-height: calc(100vh - 76px); */
    margin-top: 130px;
}
            /* E N D   M A I N   P  A G E   */
        /* S T A R T   L I S T I N G  */
.img-listing
{
    border-radius: 20px;
    width: 100%;
    height: 200px;
    object-fit: cover;
}
@media ( 300px  <= width <= 575px) {
    .img-listing
    {
        max-height: 306px;
    }

}
.code-desc
{
    font-size: 14px;
    margin-top: -6px;
}
.title-card p 
{
    font-size: 18px ;
    font-weight: 600;
}
        /* E N D   L I S T I N G  */
        /* S T A R T   U N I T S  */
.line-unit
{
    width: 60%;
    color: $lightGray;
}
.image-unit{
    height: 40%;
}
.resize-main-image
{
    width: 100%;
    object-fit: cover;
    height: 415px;
}
.resize-second-image    
{
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 195px;
}
.border-img 
{
    border-radius: 20px;
}
.code-title-unit
{
    font-size: 14px;
}
.star-review-unit
{
    color: $thirdColor;
}
.guarantee-unit,
.desc-unit,
.bar-unit,
.services-unit,
.bookingCalender-unit,
.location-unit,
.policy-cancellation-unit
{
    /* margin-block: 30px; */
    margin: 30px 0;
}
.bookingCalender-unit
{
    overflow: hidden;
}
.img-guarantee-unit
{
    height: 40px;
    width: 60px;
}
.guarantee-unit ul 
{
    list-style: disc;
}
.bar-unit
{
    /* margin-block: 40px; */
    margin: 40px 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    /* padding-block: 10px; */
    padding: 10px 0;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}
.button-services
{
    border: 1px solid $mediumGray;
    border-radius: 50px;
    background-color: transparent;
    color: #000;
    text-decoration: underline;
    /* margin-block: 10px; */
    margin: 10px 0;
    padding: 5px 30px;
}
            /* START CONTACTS POPUP */
.contact-btn .primary-small-button, .contact-btn .secondary-small-button {
    width: 120px;
}
            /* END CONTACTS POPUP  */
    /* START Date Range Picker => react dates  */
.DateInput_input, .DateRangePickerInput, .DateInput
{
    background-color: transparent !important;
}
.DateInput
{
    border: 0;
    width: 48% !important;
}
.DateRangePickerInput__withBorder
{
    border: 0;
    border-bottom: none !important; 
    width: fit-content !important;
    border: 1px solid $lightGray !important;
}
.DateRangePickerInput_arrow_svg
{
    display: none;
}
.DateRangePickerInput_arrow
{
    background-color: #727272;
    width: 10px;
    height: 2px;
}
.DateRangePickerInput_clearDates_default:hover
{
    background-color: transparent;
}
.CalendarDay__default:hover
{
    background: $babyBlue-100 !important;
    border: 1px solid $babyBlue-100 !important;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover
{
    background-color: $primaryColor !important;
}
.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover
{
    background: $primaryTile !important;
    border: 1px double $primaryTile !important;
}
.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover
{
    border-color: $primaryTile  !important;
    background-color: #0d5bf880 !important;
    color: $babyBlue-100 !important;
}
.CalendarDay__selected_span
{
    border-color: $primaryTile  !important;
    background-color: #0d5bf880 !important;
}
.DateInput_input__focused
{
    border-bottom : 2px solid $primaryColor ;
    border-bottom : none !important;
}
.DateRangePickerInput__rtl
{
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    padding: 8px 3px;
}
.DateInput_input {
    font-size: 16px !important;
}
                /* End DateRange Picker */
.policy-item-1, .policy-item-2
{
    min-width: fit-content;
}
.booking-unit
{
    border: 1px #DDD solid;
    border-radius: 20px;
    padding: 25px;
    width: 330px;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
}
.individuals-booking
{
    width: 100%;
    border: 1px solid $lightGray;
    border-bottom-left-radius: 20px ;
    border-bottom-right-radius: 20px ;
    padding: 13px 13px;
    border-top: 0;
}
.booking-button
{
    width : 100%;
    border-radius: 20px;
    background-color: $primaryColor;
    color: $baseColor;
    border: 0;
    margin: 10px 0;
    padding: 5px 0;
}
@media ( 991px < width < 1020px)
{
    .booking-unit
    {
        padding: 15px;
        width: 310px;
    }
}
@media ( 768px <= width < 940px )
{
    .booking-unit
    {
        padding: 10px;
        width: 240px;
    }
    .calc-booking p {
        font-size: 14px;
    }
    .DateInput {
        width: 100px !important;
    }
    .DateInput_input {
        font-size: 14px !important;
    }
}
.dropdown-individuals-input
{
    width: 100%;
    background-color: $babyBlue-100;
    padding: 20px;
    border-radius: $borderRadius;
}
.icon-booking
{
    border-radius: 50%;
    border: 1px solid $darkGray;
    color: $darkGray;
    padding: 7px;
}
.btn-icon-guests
{
    border: 0;
    background-color: transparent;
}
.icon-booking:hover {
    border: 1px solid #000000;
    color: #000000;
}
.line-booking
{
    width: 80%;
    color: $lightGray;
    margin-inline: auto ;
}
.booking-button-mobile
{
    width: 100%;
    border-radius: 20px;
    background-color: $primaryColor;
    color: $baseColor;
    border: 0;
    padding: 10px 30px;
}
.footer-booking-unit-mobile
{
    background-color: $baseColor;
    padding: 10px 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #DDD;
}

        /* START POPUP Filter */
/* .containerBox
{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
    height: 100vh;
    width: 100vw;
    inset: 0;
    z-index: 999;
}
.lightContainer
{
    position: absolute;
    top: calc(50% - 125px);
    right: calc(50% - 250px);
    min-width: 500px;
    margin: auto;
}
.popup-container
{
    padding: 15px 30px 15px 30px;
    background-color: $baseColor;
    border-radius: 20px;
} */


            /*START SELECT-REACT */
// .parent-search
// {
//     /* background-color: $baseColor; */
//     background-color: $baseColor;
//     position: relative;
//     top: 116px;
//     z-index: 1000;
//     /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
// }
.search
{
    position: relative;
    width: 70%;
    margin-inline: auto;
    padding: 20px 0;
}
.layout-search
{
    position: fixed;
    inset: 0;
    background-color: #0000004d;
    z-index: 9;
}
// .parent-search .css-13cymwt-control
// {
//     background-color: transparent;
//     border: 1px solid $primaryColor;
//     border-radius: 20px;
//     width: 100%;
//     padding: 15px 14px 15px 40px;
//     font-size: 16px;
//     margin-inline: auto;
//     border-radius: 20px !important;
// }
.css-7t1uod-control
{
    border-radius: 10px !important;
}
.css-2i78nt-control{
    border-radius: 10px !important  ;
}
// .parent-search .css-1xc3v61-indicatorContainer
// {
//     /* opacity: 0; */
// }
// .parent-search .css-vnxml5-control
// {
//     outline: -webkit-focus-ring-color auto 0px;
//     padding: 15px 14px 15px 40px;
//     font-size: 16px;
//     border-radius: 20px !important;
// }
// .parent-search .css-tj5bde-Svg
// {
//     /* opacity: 0; */
// }
// .parent-search .css-1u9des2-indicatorSeparator
// {
//     /* opacity: 0; */
// }
// .parent-search .css-qbdosj-Input {
//     color: $primaryColor !important;
// }
// .parent-search .css-1wy0on6 {
//     /* opacity: 0 !important; */
// }
// .parent-search .parent-icon-search-navbar-old
// {
//     position: absolute;
//     top: 50%;
//     left: 5px;
//     transform: translateY(-50%);
//     border: 0;
// }
// .parent-search .css-15lsz6c-indicatorContainer
// {
//     /* color: transparent !important; */
// }
    /* START BASE POPUP */
.containerBox
{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
    // height: 100vh;
    // width: 100vw;
    inset: 0;
    z-index: 999;
}
.lightContainer
{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) ;
    min-width: 570px;
    max-width: 500px;
    @media(0px  <= width <= 600px)
    {
        left: 50%;
        top: 50%;
        width: calc(100%);
        min-width: fit-content;
    }
}


.popup-container
{
    padding: 15px 30px 15px 30px;
    background-color: $baseColor;
    border-radius: 20px;
}
/* END BASE POPUP */

/* START POPUP CONTACTS */
.xmark-contacts-popup {
    position: absolute;
    top: 12px;
    right: 15px;
}
    /* M O B I L E    B O O K I N G */
.booking-unit-mobile
{
    padding: 25px;
    width: 100%;
}
.booking-unit-mobile .DateInput
{
    width: 48% !important;
}
.booking-unit-mobile .DateInput_input {
    font-size: 18px !important;
}
.individuals-booking-mobile
{
    width: 100%;
    border: 1px solid $lightGray;
    border-bottom-left-radius: 20px ;
    border-bottom-right-radius: 20px ;
    padding: 13px 13px;
    border-top: 0;
}
@media (767px >= width > 500px) {
    .booking-unit-mobile
    {
        width: 412px;
    }
}
.icon-prev-booking
{
    color: $darkGray;
    cursor: pointer;
}
.custom-toast
{
    font-family: 'Cairo', sans-serif !important;
}
            /* START SWIPER */
.swiper-button-next,
.swiper-button-prev {
  color: #333333; /* Arrow color */
  background-color: #fff !important; /* Background color */
  border-radius: 50% !important; /* Border radius */
  width: 40px !important; /* Arrow width */
  height: 40px !important; /* Arrow height */
}
.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 12px !important;
    font-weight: 700;

}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset,15px);
}
.swiper-button-next,
.swiper-button-prev {
    color: #333333;
    background-color: #ffffffd0 !important;
    /* color: transparent; 
    background-color: transparent !important;   */
    border-radius: 50% !important; /* Border radius */
    width: 30px !important; /* Arrow width */
    height: 30px !important; /* Arrow height */
    /* opacity: 0; */
}
.small-slider .swiper-button-next::after,
.small-slider .swiper-button-prev::after {
    font-size: 10px !important;
    font-weight: 700;
}
.small-slider .swiper-button-next, .small-slider .swiper-rtl .swiper-button-prev {
    top: 50%;
    transform: translateY(50%);
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction
{
    bottom: var(--swiper-pagination-bottom,-2px) !important;
}

/* END SWIPER */

/* START PHONE INPUT REACT*/
.react-tel-input .country-list {
    border-radius: $borderRadius;
}
.react-tel-input .country-list .search-box 
{
    font-family: "cairo";
    font-size: 14px;
}
.react-tel-input .flag-dropdown {
    border-radius: $borderRadius;
    background-color: $babyBlue-50;
}
.react-tel-input .flag-dropdown.open {
    border-radius: $borderRadius;
}
/* END PHONE INPUT REACT*/
.ltr {
    direction: ltr;
    text-align: right !important;
}
.rtl
{
    direction: rtl;
    text-align: right !important;
}
.form-control
{
    border-color: $babyBlue-100 !important;
}
.form-control:focus
{
    border-color: $babyBlue-100 !important;
    box-shadow: none !important;
}
/* S T A R T  CallToActionTopBar */
/* .callToActionTopBar
{
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    padding: 10px 10px;
    background-color: $primaryColor;
}
.text-callToAction
{
    text-decoration: underline !important;
    cursor: pointer;
    color: $baseColor !important;
}
.text-callToAction:hover
{
    color: $secondryColor !important;
}
.close-callToActionTopBar
{
    position: absolute;
    left: 0;
    padding: 0 0 0 10px;
    color: $baseColor;
    cursor: pointer;
} */
/* E N D  CallToActionTopBar */


                    /* SCSS */

@import "../css/globals.css";

%form-input {
    color: #272e3a;
    font-size: 16px;
    line-height: 1.5;
    text-align: right;
    // height: 48px;
    padding: 12px 10px;
    // transition: all .2s ease-in-out;
    width: 100%;
    // border-radius: $borderRadius;
    // border-radius: 50px;
    border: none;

    &:focus-visible {
        outline: none;    
    }
}

%form-btn {
    width: 100%;
    text-align: center;
    background-color: $primaryColor;
    color: $baseColor;
    padding: 8px;
    border-radius: $borderRadius;
    margin-top: 20px;
    border: none;
}

%form {
    height: 100%;
    width: 100%;
    .form-container {
        width: 100%;
        height: 100%;
        padding-inline: 50px;
        // margin-top: -100px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
            // @extend %form-input;
            width: 370px;
        }
        .form-title {
            // color: $lightWhite;
        }
        .form-btn {
            @extend %form-btn;
        }
    }
   
}

.login {
    height: 100%;
    width: 100%;
    .login-form {
        width: 100%;
        height: 100%;
        padding-inline: 50px;
        // margin-top: -100px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
            // @extend %form-input;
            width: 370px;
        }
        .login-title {
            // color: $lightWhite;
        }
        .login-btn {
            @extend %form-btn;
        }
    }
   
}
.registration {
    // margin-top: 120px;
    margin-bottom: 100px;
    // padding-inline: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    // text-align: center;
    .registration-form {
        width: 100%;
        // .form-input {
        //     @extend %form-input;
        // }
        .registration-title {
            // color: $lightWhite;
            text-align: center
        }
        .registration-btn {
            @extend %form-btn;
        }
        .phone-input {
            .react-tel-input .form-control {
                width: 100%;
                border-radius: $borderRadius;
            }
        }
    }
    // .registration-img {
    //     width: 50%;
    //     height: 100vh;
    //     position: relative;
    //     .overlay-registration-pic {
    //         position: absolute;
    //         width: 100%;
    //         height: 100%;
    //         background-color: #323232db;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         img {
    //             width: 50% ;
    //             height: 50% ;
    //         }
    //     }
    //     @media (max-width : 761px) {
    //         display: none;
    //     }
    //     img {
    //         @media (max-width : 991px) {
    //             width: 90% !important;
    //         }
    //     }
    // }
    @media (max-width : 991px) {
        // margin-top: 50px;
    }
    @media (max-width : 761px) {
        // margin-top: 50px;
        padding-inline: 10px;
    }
}
.registration-page {
    width: 50%;
    margin: 0 auto;
    @extend .registration;
    @media (max-width : 991px) {
        width: 100%;
    }
}


.payment-invoice {
    .invoice-details {
        width: 50%;
        @media (max-width : 767px) {
            width: 100%;
        }
    }
    .invoice-details-line {
        width: 100%;
        color: $lightGray;
        margin-inline: auto;
    }
}

.success-paid-message-container {
    .success-icon {
        font-size: 48px;
        color: $primaryColor;
    }
}
.failure-paid-message-container {
    .failure-icon {
        font-size: 48px;
        color: $primaryColor;
    }
}
.smile-icon {
    width: 35px;
}

// .signin-popup {
//     .lightContainer
//     {
//         position: absolute;
//         top: calc(50%);
//         right: calc(50%);
//         transform: translate(50%,-50%) ;
//         width: 80%;
//         min-width: 500px;
//         max-width: none;
//         /* margin: auto; */
//         .popup-container {
//             padding: 15px 10px;
//         }
//         .login .login-form input {
//             width: 100% !important;
//             // min-width: 150px;
//         }
//         .signin-line {
//             display: block;
//             border-right: 1px solid $babyBlue-100;
//             height: 100%;
//             width: 1px;
//         }
//     }
    
// }

            //  Start R E A C T   B O O T S T R A P
.dropdown-toggle::after {
    display: none;
}

.forget-password-confirm-email {
    @extend %form;
}
.set-new-password {
    @extend %form;
}