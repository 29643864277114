@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
@import "./globals.css";
/* GLOBAL CLASSES */
@import "../css/globals.css";
.test {
  color: #05389b;
}

a {
  text-decoration: none !important;
  color: black !important;
}

p {
  margin: 0 !important;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 0;
}

.text-myPrimary {
  color: #074acc;
}

.text-myMediumGray {
  color: #808080;
}

.fs-14 {
  font-size: 14px;
}

.myFs-10 {
  font-size: 10px;
  color: #808080;
}

.w-fitContent {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.cursor-pointer {
  cursor: pointer;
}

.myText-warning {
  color: #FF9E01;
  font-size: 14px;
  margin-top: -10px;
}

/*  START CUSTOMIZE BTN */
.primary-small-button, .secondary-small-button {
  color: #fff;
  font-size: 14px;
  border-radius: 10px;
  padding: 10px;
  border: 0;
}

.primary-medium-button {
  color: #fff;
  font-size: 16px;
  border-radius: 10px;
  padding: 15px;
  border: 0;
  min-width: 70px;
}

.primary-small-button, .primary-medium-button {
  background-color: #074acc;
}

.secondary-small-button {
  background-color: #808080;
}

/* END CUSTOMIZE BTN */
/* START PRIMARY INPUT */
.primary-input {
  border-radius: 10px;
  padding: 5px 10px;
  background-color: transparent;
  border: 1px solid #D3DFF7;
  width: 100%;
}
.primary-input::-moz-placeholder {
  font-size: 16px;
}
.primary-input::placeholder {
  font-size: 16px;
}
.primary-input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  font-size: 16px;
  padding: 5px 10px !important;
}

/* END PRIMARY INPUT */
.text-error {
  color: #CC3F0C;
  font-size: 14px;
}

.filter-popup, .contacts-popup {
  display: none;
}

/* S T A R T   N A V B A R  */
.navbar {
  position: fixed !important;
  /*  call to action */
  top: 0px;
  right: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 76px;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.navbar .logo-navbar {
  max-height: 56px;
}
.navbar .icon-search-navbar {
  color: #074acc;
  border-radius: 15px;
  font-size: 24px;
}
.navbar .filter {
  color: #074acc;
  font-size: 24px;
}
.navbar .profile-menu-dropdown .dropdown-toggle {
  background-color: #fff;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px !important;
  width: 90px;
}
.navbar .profile-menu-dropdown .dropdown-toggle .profile-menu-dropdown-toggle {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar .profile-menu-dropdown .dropdown-toggle .profile-menu-dropdown-toggle .profile-icon {
  fill: #074acc;
  font-size: 20px !important;
}
.navbar .profile-menu-dropdown .dropdown-toggle .profile-menu-dropdown-toggle .menu-icon {
  color: #333333;
  font-size: 22px;
}
.navbar .profile-menu-dropdown .dropdown-item.active, .navbar .profile-menu-dropdown .dropdown-item:active {
  background-color: #EFF3FB;
}

.search-navbar {
  display: none;
}
.search-navbar .layout-search {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3019607843);
  z-index: 9;
}
.search-navbar .layout-search .parent-search {
  /* background-color: $baseColor; */
  background-color: #fff;
  position: relative;
  top: 76px;
  z-index: 1000;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
.search-navbar .layout-search .parent-search .search {
  position: relative;
  width: 70%;
  margin-inline: auto;
  padding: 20px 0;
}
.search-navbar .layout-search .parent-search .search .icon-search-navbar-old {
  background-color: #074acc;
  color: #fff;
  border-radius: 20px;
  font-size: 32px;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
.search-navbar .layout-search .parent-search .search .css-13cymwt-control {
  background-color: transparent;
  border: 1px solid #074acc;
  border-radius: 20px;
  width: 100%;
  padding: 15px 14px 15px 40px;
  font-size: 16px;
  margin-inline: auto;
  border-radius: 20px !important;
}
.search-navbar .layout-search .parent-search .search .css-1xc3v61-indicatorContainer {
  /* opacity: 0; */
}
.search-navbar .layout-search .parent-search .search .css-vnxml5-control {
  outline: -webkit-focus-ring-color auto 0px;
  padding: 15px 14px 15px 40px;
  font-size: 16px;
  border-radius: 20px !important;
}
.search-navbar .layout-search .parent-search .search .css-tj5bde-Svg {
  /* opacity: 0; */
}
.search-navbar .layout-search .parent-search .search .css-1u9des2-indicatorSeparator {
  /* opacity: 0; */
}
.search-navbar .layout-search .parent-search .search .css-qbdosj-Input {
  color: #074acc !important;
}
.search-navbar .layout-search .parent-search .search .css-1wy0on6 {
  /* opacity: 0 !important; */
}
.search-navbar .layout-search .parent-search .search .parent-icon-search-navbar-old {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  border: 0;
}
.search-navbar .layout-search .parent-search .search .css-15lsz6c-indicatorContainer {
  /* color: transparent !important; */
}

/* E N D   N A V B A R  */
/* S T A R T   F O O T E R  */
.footer {
  width: 100%;
  padding: 10px 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9;
  background-color: #fff;
}

.preview-unit-footer .footer {
  box-shadow: none;
  background-color: transparent;
  position: static;
}

.line-footer {
  border-top: 1px solid rgba(221, 221, 221, 0.7607843137);
  text-align: right;
  width: 100%;
}

.preview-unit-footer {
  margin-bottom: 65px;
}

.parent-preview-unit-footer {
  border-top: 1px solid rgba(221, 221, 221, 0.7607843137);
  /* background-color: var(--babyBlue-50); */
}

.fs-footer {
  font-size: 14px;
}

.logo-footer {
  height: 45px;
}

/* E N D   F O O T E R  */
/* S T A R T   M A I N   P  A G E   */
.main {
  /* min-height: calc(100vh - 76px); */
  margin-top: 130px;
}

/* E N D   M A I N   P  A G E   */
/* S T A R T   L I S T I N G  */
.img-listing {
  border-radius: 20px;
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (300px <= width <= 575px) {
  .img-listing {
    max-height: 306px;
  }
}
.code-desc {
  font-size: 14px;
  margin-top: -6px;
}

.title-card p {
  font-size: 18px;
  font-weight: 600;
}

/* E N D   L I S T I N G  */
/* S T A R T   U N I T S  */
.line-unit {
  width: 60%;
  color: #999999;
}

.image-unit {
  height: 40%;
}

.resize-main-image {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 415px;
}

.resize-second-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  max-height: 195px;
}

.border-img {
  border-radius: 20px;
}

.code-title-unit {
  font-size: 14px;
}

.star-review-unit {
  color: #0FAC83;
}

.guarantee-unit,
.desc-unit,
.bar-unit,
.services-unit,
.bookingCalender-unit,
.location-unit,
.policy-cancellation-unit {
  /* margin-block: 30px; */
  margin: 30px 0;
}

.bookingCalender-unit {
  overflow: hidden;
}

.img-guarantee-unit {
  height: 40px;
  width: 60px;
}

.guarantee-unit ul {
  list-style: disc;
}

.bar-unit {
  /* margin-block: 40px; */
  margin: 40px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* padding-block: 10px; */
  padding: 10px 0;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}

.button-services {
  border: 1px solid #808080;
  border-radius: 50px;
  background-color: transparent;
  color: #000;
  text-decoration: underline;
  /* margin-block: 10px; */
  margin: 10px 0;
  padding: 5px 30px;
}

/* START CONTACTS POPUP */
.contact-btn .primary-small-button, .contact-btn .secondary-small-button {
  width: 120px;
}

/* END CONTACTS POPUP  */
/* START Date Range Picker => react dates  */
.DateInput_input, .DateRangePickerInput, .DateInput {
  background-color: transparent !important;
}

.DateInput {
  border: 0;
  width: 48% !important;
}

.DateRangePickerInput__withBorder {
  border: 0;
  border-bottom: none !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  border: 1px solid #999999 !important;
}

.DateRangePickerInput_arrow_svg {
  display: none;
}

.DateRangePickerInput_arrow {
  background-color: #727272;
  width: 10px;
  height: 2px;
}

.DateRangePickerInput_clearDates_default:hover {
  background-color: transparent;
}

.CalendarDay__default:hover {
  background: #D3DFF7 !important;
  border: 1px solid #D3DFF7 !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background-color: #074acc !important;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  background: rgba(13, 91, 248, 0.7490196078) !important;
  border: 1px double rgba(13, 91, 248, 0.7490196078) !important;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  border-color: rgba(13, 91, 248, 0.7490196078) !important;
  background-color: rgba(13, 91, 248, 0.5019607843) !important;
  color: #D3DFF7 !important;
}

.CalendarDay__selected_span {
  border-color: rgba(13, 91, 248, 0.7490196078) !important;
  background-color: rgba(13, 91, 248, 0.5019607843) !important;
}

.DateInput_input__focused {
  border-bottom: 2px solid #074acc;
  border-bottom: none !important;
}

.DateRangePickerInput__rtl {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  padding: 8px 3px;
}

.DateInput_input {
  font-size: 16px !important;
}

/* End DateRange Picker */
.policy-item-1, .policy-item-2 {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.booking-unit {
  border: 1px #DDD solid;
  border-radius: 20px;
  padding: 25px;
  width: 330px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
}

.individuals-booking {
  width: 100%;
  border: 1px solid #999999;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 13px 13px;
  border-top: 0;
}

.booking-button {
  width: 100%;
  border-radius: 20px;
  background-color: #074acc;
  color: #fff;
  border: 0;
  margin: 10px 0;
  padding: 5px 0;
}

@media (991px < width < 1020px) {
  .booking-unit {
    padding: 15px;
    width: 310px;
  }
}
@media (768px <= width < 940px) {
  .booking-unit {
    padding: 10px;
    width: 240px;
  }
  .calc-booking p {
    font-size: 14px;
  }
  .DateInput {
    width: 100px !important;
  }
  .DateInput_input {
    font-size: 14px !important;
  }
}
.dropdown-individuals-input {
  width: 100%;
  background-color: #D3DFF7;
  padding: 20px;
  border-radius: 10px;
}

.icon-booking {
  border-radius: 50%;
  border: 1px solid #333333;
  color: #333333;
  padding: 7px;
}

.btn-icon-guests {
  border: 0;
  background-color: transparent;
}

.icon-booking:hover {
  border: 1px solid #000000;
  color: #000000;
}

.line-booking {
  width: 80%;
  color: #999999;
  margin-inline: auto;
}

.booking-button-mobile {
  width: 100%;
  border-radius: 20px;
  background-color: #074acc;
  color: #fff;
  border: 0;
  padding: 10px 30px;
}

.footer-booking-unit-mobile {
  background-color: #fff;
  padding: 10px 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid #DDD;
}

/* START POPUP Filter */
/* .containerBox
{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
    height: 100vh;
    width: 100vw;
    inset: 0;
    z-index: 999;
}
.lightContainer
{
    position: absolute;
    top: calc(50% - 125px);
    right: calc(50% - 250px);
    min-width: 500px;
    margin: auto;
}
.popup-container
{
    padding: 15px 30px 15px 30px;
    background-color: $baseColor;
    border-radius: 20px;
} */
/*START SELECT-REACT */
.search {
  position: relative;
  width: 70%;
  margin-inline: auto;
  padding: 20px 0;
}

.layout-search {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3019607843);
  z-index: 9;
}

.css-7t1uod-control {
  border-radius: 10px !important;
}

.css-2i78nt-control {
  border-radius: 10px !important;
}

/* START BASE POPUP */
.containerBox {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  inset: 0;
  z-index: 999;
}

.lightContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 570px;
  max-width: 500px;
}
@media (0px <= width <= 600px) {
  .lightContainer {
    left: 50%;
    top: 50%;
    width: 100%;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

.popup-container {
  padding: 15px 30px 15px 30px;
  background-color: #fff;
  border-radius: 20px;
}

/* END BASE POPUP */
/* START POPUP CONTACTS */
.xmark-contacts-popup {
  position: absolute;
  top: 12px;
  right: 15px;
}

/* M O B I L E    B O O K I N G */
.booking-unit-mobile {
  padding: 25px;
  width: 100%;
}

.booking-unit-mobile .DateInput {
  width: 48% !important;
}

.booking-unit-mobile .DateInput_input {
  font-size: 18px !important;
}

.individuals-booking-mobile {
  width: 100%;
  border: 1px solid #999999;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 13px 13px;
  border-top: 0;
}

@media (767px >= width > 500px) {
  .booking-unit-mobile {
    width: 412px;
  }
}
.icon-prev-booking {
  color: #333333;
  cursor: pointer;
}

.custom-toast {
  font-family: "Cairo", sans-serif !important;
}

/* START SWIPER */
.swiper-button-next,
.swiper-button-prev {
  color: #333333; /* Arrow color */
  background-color: #fff !important; /* Background color */
  border-radius: 50% !important; /* Border radius */
  width: 40px !important; /* Arrow width */
  height: 40px !important; /* Arrow height */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 12px !important;
  font-weight: 700;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 15px);
}

.swiper-button-next,
.swiper-button-prev {
  color: #333333;
  background-color: rgba(255, 255, 255, 0.8156862745) !important;
  /* color: transparent; 
  background-color: transparent !important;   */
  border-radius: 50% !important; /* Border radius */
  width: 30px !important; /* Arrow width */
  height: 30px !important; /* Arrow height */
  /* opacity: 0; */
}

.small-slider .swiper-button-next::after,
.small-slider .swiper-button-prev::after {
  font-size: 10px !important;
  font-weight: 700;
}

.small-slider .swiper-button-next, .small-slider .swiper-rtl .swiper-button-prev {
  top: 50%;
  transform: translateY(50%);
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, -2px) !important;
}

/* END SWIPER */
/* START PHONE INPUT REACT*/
.react-tel-input .country-list {
  border-radius: 10px;
}

.react-tel-input .country-list .search-box {
  font-family: "cairo";
  font-size: 14px;
}

.react-tel-input .flag-dropdown {
  border-radius: 10px;
  background-color: #EFF3FB;
}

.react-tel-input .flag-dropdown.open {
  border-radius: 10px;
}

/* END PHONE INPUT REACT*/
.ltr {
  direction: ltr;
  text-align: right !important;
}

.rtl {
  direction: rtl;
  text-align: right !important;
}

.form-control {
  border-color: #D3DFF7 !important;
}

.form-control:focus {
  border-color: #D3DFF7 !important;
  box-shadow: none !important;
}

/* S T A R T  CallToActionTopBar */
/* .callToActionTopBar
{
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    padding: 10px 10px;
    background-color: $primaryColor;
}
.text-callToAction
{
    text-decoration: underline !important;
    cursor: pointer;
    color: $baseColor !important;
}
.text-callToAction:hover
{
    color: $secondryColor !important;
}
.close-callToActionTopBar
{
    position: absolute;
    left: 0;
    padding: 0 0 0 10px;
    color: $baseColor;
    cursor: pointer;
} */
/* E N D  CallToActionTopBar */
/* SCSS */
.registration .registration-form .registration-btn, .registration-page .registration-form .registration-btn, .login .login-form .login-btn, .set-new-password .form-container .form-btn, .forget-password-confirm-email .form-container .form-btn {
  width: 100%;
  text-align: center;
  background-color: #074acc;
  color: #fff;
  padding: 8px;
  border-radius: 10px;
  margin-top: 20px;
  border: none;
}

.set-new-password, .forget-password-confirm-email {
  height: 100%;
  width: 100%;
}
.set-new-password .form-container, .forget-password-confirm-email .form-container {
  width: 100%;
  height: 100%;
  padding-inline: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.set-new-password .form-container input, .forget-password-confirm-email .form-container input {
  width: 370px;
}
.login {
  height: 100%;
  width: 100%;
}
.login .login-form {
  width: 100%;
  height: 100%;
  padding-inline: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .login-form input {
  width: 370px;
}
.registration, .registration-page {
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registration .registration-form, .registration-page .registration-form {
  width: 100%;
}
.registration .registration-form .registration-title, .registration-page .registration-form .registration-title {
  text-align: center;
}
.registration .registration-form .phone-input .react-tel-input .form-control, .registration-page .registration-form .phone-input .react-tel-input .form-control {
  width: 100%;
  border-radius: 10px;
}
@media (max-width: 761px) {
  .registration, .registration-page {
    padding-inline: 10px;
  }
}

.registration-page {
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .registration-page {
    width: 100%;
  }
}

.payment-invoice .invoice-details {
  width: 50%;
}
@media (max-width: 767px) {
  .payment-invoice .invoice-details {
    width: 100%;
  }
}
.payment-invoice .invoice-details-line {
  width: 100%;
  color: #999999;
  margin-inline: auto;
}

.success-paid-message-container .success-icon {
  font-size: 48px;
  color: #074acc;
}

.failure-paid-message-container .failure-icon {
  font-size: 48px;
  color: #074acc;
}

.smile-icon {
  width: 35px;
}

.dropdown-toggle::after {
  display: none;
}/*# sourceMappingURL=style-scss.css.map */