@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cairo&display=swap');


*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root
{
  --baseColor : #fff;
  --babyBlue-50 : #EFF3FB;
  --babyBlue-75 : #EBEFFE;
  --babyBlue-100 : #D3DFF7;

  --darkPrimary : #05389b;
  --primaryColor : #074acc;
  --secondryColor : #FF9E01;
  --thirdColor : #0FAC83;
  --fourthColor : #CC3F0C;
  --fifthColor : #18BECB;

  --lightGray: #999999;
  --mediumGray : #808080;
  --darkGray : #333333;

  --primaryTile : #0d5bf8bf;
  --secondaryTile : rgba(255, 157, 0, 0.75);
  --thirdTile : rgba(15, 174, 131, 0.75);
  --fourthTile : rgba(204, 64, 13, 0.75);
  --fifthTile : rgba(24, 191, 203, 0.75);    
}
body
{
  font-family: 'Cairo', sans-serif;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
} */

/* * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
} */

/* html,
body {
  max-width: 100vw;
  overflow-x: hidden;
} */
/* 
body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */
