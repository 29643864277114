@import "./globals.css";

@media ( 1201px  <= width <= 1600px) {
    
}
@media ( 993px  <= width <= 1200px) {
   
}
@media ( 768px  <= width <= 992px) {
    
}
@media ( 0px  <= width <= 767px) {
    .line-unit
    {
        width: 100%;
        color: var(--lightGray);
    }
    .bar-unit h5 
    {
        font-size: 18px !important;
    }
    .secure-mobile-view
    {
        display: none;
    }
    .text-callToActionTopBar
    {
        font-size: 14px;
    }
}
@media (width >= 767px) {
    .secure-desktop-view
    {
        display: none;   
    }
}
@media ( 0px  <= width <= 575px) {
    .fs-footer
    {
        font-size: 12px;
    }
    .img-listing
    {
        width: 80%;
    }
}